import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import styled from "styled-components"
import { rem } from "polished"
import { ImagesCarousel } from "@outdoormap/gatsby-plugin-brand-sites"

import HexagonIcon from "../hexagon-icon"
import { Label3 } from "../styled/label"
import { Heading1 } from "../styled/heading"
import { Paragraph, ParagraphLarge } from "../styled/paragraph"
import { ReactComponent as SvgCalendar } from "../../assets/images/icons/calendar.svg"

const Container = styled.header`
  ${({ theme }) => theme.grid.container()}
`

const Inner = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  border-radius: ${rem(8)};
  background-color: ${({ theme }) => theme.colors.white};
`

const Info = styled.div`
  width: 50%;
  padding: ${rem(40)} ${rem(50)};
  display: flex;
  flex-direction: column;

  @media ${({ theme }) => theme.mq.largeDown} {
    width: 100%;
  }

  @media ${({ theme }) => theme.mq.smallDown} {
    padding: ${rem(20)};
  }
`

const Label = styled.div`
  margin-bottom: 1.5em;
  display: inline-flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.green};

  ${Label3} {
    margin-left: 0.75em;
    color: inherit;
  }

  .-icon {
    @media ${({ theme }) => theme.mq.smallDown} {
      width: 2em;
      height: 2em;
    }
  }
`

const Date = styled(Paragraph)`
  margin-bottom: 0.875em;
  color: ${({ theme }) => theme.colors.green};
`

const Title = styled(Heading1)`
  max-width: ${rem(420)};
  margin-bottom: 0.5em;
`

const Tagline = styled(ParagraphLarge)`
  color: ${({ theme }) => theme.colors.green};
`

const Images = styled.div`
  width: 50%;

  @media ${({ theme }) => theme.mq.largeDown} {
    width: 100%;
    aspect-ratio: 16 / 9;
  }
`

const EventHero = ({ data: { title, tagline, date, images } = {} }) => {
  const { translations } = useStaticQuery(graphql`
    query {
      translations: translationsJson {
        global {
          event
        }
      }
    }
  `)

  return (
    <Container>
      <Inner>
        <Info>
          <Label>
            <HexagonIcon
              iconSvg={<SvgCalendar />}
              className="-icon"
              aria-hidden="true"
            />

            <Label3
              dangerouslySetInnerHTML={{ __html: translations.global.event }}
            />
          </Label>

          {date && (
            <Date datetime={date}>
              <span dangerouslySetInnerHTML={{ __html: date }} />
            </Date>
          )}

          {title && <Title dangerouslySetInnerHTML={{ __html: title }} />}

          {tagline && <Tagline dangerouslySetInnerHTML={{ __html: tagline }} />}
        </Info>

        <Images>
          <ImagesCarousel data={images} title={title} />
        </Images>
      </Inner>
    </Container>
  )
}

EventHero.propTypes = {
  data: PropTypes.object,
}

export default EventHero
