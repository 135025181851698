import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import styled from "styled-components"
import { rem, hideVisually } from "polished"
import { Meta } from "@outdoormap/gatsby-plugin-brand-sites"

import Layout from "../components/layout"
import Styled from "../components/styled"
import EventHero from "../components/event-hero"
import Map from "../components/map"
import Infobox from "../components/infobox"
import SitesCarousel from "../components/sites-carousel"
import { Label3 } from "../components/styled/label"
import { paragraphLargeStyles } from "../components/styled/paragraph"

const Sites = styled.section`
  margin-top: ${rem(40)};
  padding-top: ${rem(40)};
  border-top: 1px solid ${({ theme }) => theme.colors.white};

  @media ${({ theme }) => theme.mq.mediumDown} {
    margin-top: ${rem(20)};
    padding-top: ${rem(20)};
  }

  ${Label3} {
    margin-bottom: ${rem(20)};
    color: ${({ theme }) => theme.colors.white};
  }
`

const Columns = styled.div`
  ${({ theme }) => theme.grid.container(`narrow`)}
  ${({ theme }) => theme.grid.grid()}
  ${({ theme }) => theme.grid.gridGutterX()}

  margin-top: ${rem(50)};
  margin-bottom: ${rem(50)};

  @media ${({ theme }) => theme.mq.mediumDown} {
    margin-bottom: 0;
  }
`

const ColumnMain = styled.div`
  ${({ theme }) => theme.grid.cell(8)}

  padding-right: 10%;

  @media ${({ theme }) => theme.mq.mediumDown} {
    ${({ theme }) => theme.grid.cell(12)}
  }

  ${Sites} {
    @media ${({ theme }) => theme.mq.mediumDown} {
      display: none;
    }
  }
`

const ColumnAside = styled.aside`
  ${({ theme }) => theme.grid.cell(4)}

  @media ${({ theme }) => theme.mq.mediumDown} {
    ${({ theme }) => theme.grid.cell(12)}

    margin-top: ${rem(30)};
  }
`

const Content = styled(Styled)`
  p:first-child {
    ${paragraphLargeStyles}
  }

  > *:not(:first-child) {
    max-width: ${rem(520)};
  }
`

const MapWrap = styled.section`
  height: ${rem(240)};
  margin-bottom: ${rem(10)};
  overflow: hidden;
  border-radius: ${rem(5)};

  > h2 {
    ${hideVisually()}
  }
`

const SitesMobile = styled.footer`
  ${({ theme }) => theme.grid.container(`narrow`)}

  margin-bottom: ${rem(40)};
  display: none;

  @media ${({ theme }) => theme.mq.mediumDown} {
    display: block;
  }
`

const EventPostTemplate = ({ data: { translations, event } }) => {
  const SitesComponent = () => {
    if (!event.sites?.length) return null

    return (
      <Sites>
        <Label3
          as="h2"
          dangerouslySetInnerHTML={{
            __html: translations.global.relatedPlaces,
          }}
        />

        <SitesCarousel visibleSlides={2} data={event.sites} />
      </Sites>
    )
  }

  return (
    <Layout>
      <article>
        <EventHero data={event} />

        <Columns>
          <ColumnMain>
            {!!event.body && (
              <Content dangerouslySetInnerHTML={{ __html: event.body }} />
            )}

            <SitesComponent />
          </ColumnMain>

          <ColumnAside>
            {!!event.sites && event.sites.length && (
              <MapWrap>
                <h2
                  dangerouslySetInnerHTML={{ __html: translations.global.map }}
                />

                <Map
                  sites={event.sites.map((s) => `site=${s.id}`).join(`&`)}
                  menu="fullscreen"
                  menuActive="fullscreen"
                  filters="fullscreen"
                  scrollZoom="false"
                  styleControl="fullscreen"
                  zoomControl="fullscreen"
                  measureControl="fullscreen"
                  locationControl="fullscreen"
                  printControl="fullscreen"
                />
              </MapWrap>
            )}

            <Infobox data={event} />
          </ColumnAside>
        </Columns>

        <SitesMobile>
          <SitesComponent />
        </SitesMobile>
      </article>
    </Layout>
  )
}

EventPostTemplate.propTypes = {
  data: PropTypes.object,
}

export default EventPostTemplate

export const Head = ({ data: { event } }) => {
  return (
    <Meta
      data={{
        title: event.title,
        socialImage: event.images[0]?.images.fallback.src,
      }}
    />
  )
}

export const pageQuery = graphql`
  query ($id: String!) {
    translations: translationsJson {
      global {
        map
        relatedPlaces
      }
    }

    event: eventsJson(id: { eq: $id }) {
      title
      body
      date: occurs_at(formatString: "YYYY-MM-DD · hh:mm")
      links {
        id
        url
        text
      }
      files {
        id
        url
        text
      }
      images {
        ...NKimageFragment
      }
      sites {
        ...SitesCardFragment
      }
    }
  }
`
