import React, { useState } from "react"
import { RemoveScroll } from "react-remove-scroll"
import styled, { css } from "styled-components"
import { rem, rgba } from "polished"
import { Link } from "@outdoormap/gatsby-plugin-brand-sites"

import PrimaryNav from "./primary-nav"
import SecondaryNav from "./secondary-nav"
import { ReactComponent as DeleteSvg } from "../../assets/images/icons/delete.svg"
import { ReactComponent as MenuSvg } from "../../assets/images/icons/menu.svg"
import logoUrl from "../../assets/images/logo.svg"

const Bar = styled.div`
  flex-shrink: 0;
  flex-grow: 1;
  order: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Logo = styled.div`
  margin-top: ${rem(-10)};

  a {
    max-width: ${rem(220)};
    height: auto;
    margin: 0 auto;
    display: block;
    transition: opacity 0.2s ${({ theme }) => theme.easings.default};

    @media ${({ theme }) => theme.mq.mediumDown} {
      max-width: ${rem(160)};
    }

    &:hover {
      opacity: 0.7;
    }

    &:active {
      transition: none;
      opacity: 0.4;
    }
  }

  img {
    width: 100%;
    height: 100%;
    display: block;
  }
`

const Toggle = styled.button.attrs({ type: `button` })`
  width: 2em;
  height: 2em;
  padding: 0.4em;
  color: ${({ theme }) => theme.colors.green};

  @media ${({ theme }) => theme.mq.largeUp} {
    display: none;
  }

  svg {
    width: 100%;
    height: 100%;
    display: block;
  }
`

const NavWrap = styled.div`
  order: ${({ $type }) => ($type === `primary` ? 2 : 3)};

  ${({ $type }) =>
    $type === `secondary` &&
    css`
      display: flex;
      justify-content: flex-end;
      align-items: center;
    `}

  @media ${({ theme }) => theme.mq.largeDown} {
    display: none;
    margin-top: 1em;
    padding-top: 1em;
    border-top: 1px solid ${({ theme }) => rgba(theme.colors.white, 0.2)};
  }
`

const Inner = styled.div`
  ${({ theme }) => theme.grid.container()}

  display: flex;
  align-items: center;
  justify-content: space-between;

  @media ${({ theme }) => theme.mq.largeDown} {
    display: block;
  }
`

const Container = styled.header`
  padding-top: ${rem(20)};
  padding-bottom: ${rem(20)};
  position: relative;
  z-index: ${({ theme }) => theme.zindex.header};

  ${({ $active }) =>
    $active &&
    css`
      @media ${({ theme }) => theme.mq.largeDown} {
        ${({ theme }) => theme.helpers.hideScrollbar()}

        width: 100%;
        height: 100%;
        position: fixed;
        z-index: ${({ theme }) => theme.zindex.headerActive};
        top: 0;
        left: 0;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        will-change: scroll-position;
        background-color: ${({ theme }) => theme.colors.white};

        ${NavWrap} {
          display: block;
        }
      }
    `}
`

const Header = ({ tagName = `header` }) => {
  const [mobileActive, setMobileActive] = useState(false)

  const toggleClick = (e) => {
    e.preventDefault()
    setMobileActive(!mobileActive)
  }

  return (
    <RemoveScroll enabled={mobileActive}>
      <Container as={tagName} $active={mobileActive}>
        <Inner>
          <Bar>
            <Logo>
              <Link to="/" title="Bruksleden">
                <img src={logoUrl} alt="Bruksleden" />
              </Link>
            </Logo>

            <Toggle title="Toggle menu" onClick={toggleClick}>
              {mobileActive ? (
                <DeleteSvg aria-label="Hide menu" />
              ) : (
                <MenuSvg aria-label="Show menu" />
              )}
            </Toggle>
          </Bar>

          <NavWrap $type="primary">
            <PrimaryNav />
          </NavWrap>

          <NavWrap $type="secondary">
            <SecondaryNav />
          </NavWrap>
        </Inner>
      </Container>
    </RemoveScroll>
  )
}

export default Header
