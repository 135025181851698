import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { rem } from "polished"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "@outdoormap/gatsby-plugin-brand-sites"

import { Heading4 } from "../styled/heading"

const Image = styled.figure`
  width: 100%;
  height: 20em;
  margin-bottom: 1em;
  position: relative;
  overflow: hidden;
  border-radius: ${rem(5)};
  box-shadow: 0 ${rem(2)} ${rem(4)} rgba(0, 0, 0, 0.2);
  background-color: ${({ theme }) => theme.colors.white};

  @media ${({ theme }) => theme.mq.xsmallDown} {
    height: 16em;
  }

  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    object-fit: cover;
  }

  > ul {
    position: absolute;
    z-index: 3;
    left: 1.5em;
    bottom: 1.5em;
    display: flex;
    font-size: 0.875em;
    color: ${({ theme }) => theme.colors.white};

    li {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    li + li {
      margin-left: 1.5em;
    }

    svg {
      width: 0.9em;
      height: 0.9em;
      margin-right: 0.5em;
      path {
        fill: ${({ theme }) => theme.colors.white};
      }
    }
  }
`

const Length = styled.p`
  color: ${({ theme }) => theme.colors.green};
  margin-top: 0.3em;
`

const Container = styled.div`
  width: 100%;

  a {
    &:hover {
      ${Image} {
        opacity: 0.8;
      }
    }

    &:active {
      ${Image} {
        transition: none;
        opacity: 0.6;
      }
    }
  }

  ${Heading4} {
    width: 80%;
  }
`

const SiteCardStage = ({ data: { name, path, image, length }, ...props }) => {
  const { imgPlaceholder } = useStaticQuery(graphql`
    query {
      imgPlaceholder: file(
        sourceInstanceName: { eq: "images" }
        relativePath: { eq: "placeholder.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 768)
        }
      }
    }
  `)

  return (
    <Container {...props}>
      <Link to={path}>
        <Image>
          <GatsbyImage
            image={image || imgPlaceholder.childImageSharp.gatsbyImageData}
            alt={name}
          />
        </Image>

        {name && (
          <Heading4 as="h3" dangerouslySetInnerHTML={{ __html: name }} />
        )}

        {length && <Length>{length} km</Length>}
      </Link>
    </Container>
  )
}

SiteCardStage.propTypes = {
  data: PropTypes.object,
}

export default SiteCardStage
