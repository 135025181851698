import { rem, darken } from "polished"

const current = {
  color: ({ theme }) => theme.colors.black,
  backgroundColor: ({ theme }) => theme.colors.salmon,
  borderColor: ({ theme }) => darken(0.5, theme.colors.salmon),
  borderRadius: rem(5),
}

export default current
