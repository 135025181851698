import React, { useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { useWindowWidth } from "@react-hook/window-size/throttled"
import { Carousel } from "@outdoormap/gatsby-plugin-brand-sites"

import PostCard from "../post-card"
import { breakpoints } from "../../theme/mq"

const CarouselStyled = styled(Carousel)`
  .carousel__back-button,
  .carousel__next-button {
    top: 7.5em;

    @media ${({ theme }) => theme.mq.largeDown} {
      top: 9.5em;
    }
  }
`

const PostsCarousel = ({ data, visibleSlides, ...props }) => {
  const [theVisibileSlides, setTheVisibleSlides] = useState(visibleSlides)
  const winWidth = useWindowWidth({ initialWidth: breakpoints.xxlarge })

  if (!data || !data.length) return null

  const setVisibleSlides = (count) => {
    if (typeof window === `undefined`) return false
    window.setTimeout(() => setTheVisibleSlides(count), 50)
    return true
  }

  if (winWidth < breakpoints.xsmall) {
    if (visibleSlides >= 4 && theVisibileSlides != 1) setVisibleSlides(1)
    if (visibleSlides == 3 && theVisibileSlides != 1) setVisibleSlides(1)
    if (visibleSlides == 2 && theVisibileSlides != 1) setVisibleSlides(1)
  } else if (winWidth < breakpoints.medium) {
    if (visibleSlides >= 4 && theVisibileSlides != 2) setVisibleSlides(2)
    if (visibleSlides == 3 && theVisibileSlides != 1) setVisibleSlides(1)
  } else if (winWidth < breakpoints.xlarge) {
    if (visibleSlides >= 4 && theVisibileSlides != 3) setVisibleSlides(3)
    if (visibleSlides == 3 && theVisibileSlides != 2) setVisibleSlides(2)
  } else if (winWidth >= breakpoints.xlarge) {
    if (theVisibileSlides != visibleSlides) setVisibleSlides(visibleSlides)
  }

  const slides = data.map((item, i) => (
    <PostCard key={i} data={item} titleTag="h3" />
  ))

  return (
    <CarouselStyled
      slides={slides}
      visibleSlides={theVisibileSlides}
      {...props}
    />
  )
}

PostsCarousel.propTypes = {
  data: PropTypes.array,
  visibleSlides: PropTypes.number,
}

export default PostsCarousel
