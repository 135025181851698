import { rem, rgba } from "polished"

const infobox = {
  color: ({ theme }) => theme.colors.green,
  backgroundColor: ({ theme }) => theme.colors.white,
  borderColor: ({ theme }) => rgba(theme.colors.green, 0.2),
  iconColor: ({ theme }) => theme.colors.green,
  br: rem(5),
}

export default infobox
