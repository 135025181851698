import React from "react"
import { useStaticQuery, graphql } from "gatsby"
// import PropTypes from "prop-types"
import styled from "styled-components"
import { rem } from "polished"
import { Link } from "@outdoormap/gatsby-plugin-brand-sites"

import HexagonIcon from "../hexagon-icon"
import TypeIconSvg from "../type-icon-svg"
import { ReactComponent as ChevronDownSvg } from "../../assets/images/icons/chevron-down.svg"

const Container = styled.nav``

const Sub = styled.div`
  min-width: 150%;
  max-width: ${rem(1240)};
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 0;
  flex-wrap: wrap;
  display: none;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${rem(8)};

  @media ${({ theme }) => theme.mq.largeUp} {
    border-top-left-radius: 0;
  }

  @media ${({ theme }) => theme.mq.largeDown} {
    width: 100%;
    min-width: auto;
    position: static;
    box-shadow: 0 ${rem(2)} ${rem(6)} rgba(0, 0, 0, 0.2);
  }
`

const Base = styled.ul`
  display: flex;

  @media ${({ theme }) => theme.mq.largeDown} {
    display: block;
  }

  > li {
    position: relative;

    /* &:first-child {
      > a,
      > button {
        &::before {
          display: none;
        }
      }
    }

    &:last-child {
      > a,
      > button {
        &::after {
          display: none;
        }
      }
    } */

    &[data-active="true"],
    &:hover {
      z-index: 5000;

      /* overlay */
      &[data-has-sub="true"]::before {
        content: "";
        width: 100%;
        height: 100%;
        position: fixed;
        z-index: -1;
        top: 0;
        left: 0;
        pointer-events: none;
        background-color: rgba(0, 0, 0, 0.5);
        animation: ${({ theme }) => theme.animations.fadeIn} 0.5s;

        @media ${({ theme }) => theme.mq.largeDown} {
          display: none;
        }
      }

      > a,
      > button {
        color: ${({ theme }) => theme.colors.green};

        &:hover {
          color: ${({ theme }) => theme.colors.green};
        }

        &:active {
          color: ${({ theme }) => theme.colors.green};
        }
      }

      &[data-has-sub="true"] > a,
      &[data-has-sub="true"] > button {
        background-color: ${({ theme }) => theme.colors.white};

        /* inverted corners */
        &::before,
        &::after {
          content: "";
          width: 1em;
          height: 1em;
          position: absolute;
          bottom: 0;
          background-color: ${({ theme }) => theme.colors.white};
          mask-image: radial-gradient(
            circle 1em at 0 0,
            transparent 0,
            transparent 1em,
            black calc(1em + 1px)
          );

          @media ${({ theme }) => theme.mq.largeDown} {
            display: none;
          }
        }

        &::before {
          right: 100%;
          display: none;
        }

        &::after {
          left: 100%;
          transform: scaleX(-1);
        }
      }

      ${Sub} {
        display: flex;
      }
    }

    > a,
    > button {
      ${({ theme }) => theme.fonts.set(`primary`, `bold`)}

      padding: 1.4em 1.2em;
      display: block;
      font-size: ${rem(18)};
      white-space: nowrap;
      border-top-left-radius: 0.4em;
      border-top-right-radius: 0.4em;

      @media ${({ theme }) => theme.mq.largeDown} {
        padding: 0.5em 0;
      }

      svg {
        width: 0.7em;
        height: 0.7em;
        margin-left: 0.4em;
        display: inline-block;
      }
    }
  }
`

const SubTypesContainer = styled.div`
  width: 100%;
  padding: ${rem(30)};
  animation: ${({ theme }) => theme.animations.slideInY(-10)} 0.5s
    ${({ theme }) => theme.easings.default};

  @media ${({ theme }) => theme.mq.largeDown} {
    padding: ${rem(15)};
    font-size: 0.75em;
  }

  > a {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 0.625em;
    }

    &:hover {
      > .-icon {
        transform: scale(1.15);
      }
    }

    &:active {
      opacity: 0.6;
    }

    > .-icon {
      margin-right: 1em;
      flex-shrink: 0;
      transition: transform 0.3s ${({ theme }) => theme.easings.default};

      svg {
        path {
          fill: ${({ theme }) => theme.colors.white};
        }
      }
    }

    > span {
      ${({ theme }) => theme.fonts.set(`primary`, `bold`)}

      font-size: ${rem(18)};
      color: ${({ theme }) => theme.colors.green};
    }

    &:hover {
      > span {
        color: ${({ theme }) => theme.colors.green};
      }
    }
  }
`

const ButtonLink = ({ to, ...props }) =>
  to ? <Link to={to} {...props} /> : <button type="button" {...props} />

const SubTypes = ({ icon, data }) => {
  return (
    <SubTypesContainer>
      {data.map((type, i) => (
        <Link key={i} to={`/${type.slug}`}>
          {icon && (
            <HexagonIcon
              iconSvg={<TypeIconSvg name={type.name} />}
              className="-icon"
              aria-hidden="true"
              title={type.title}
              bgColor={type.color}
            />
          )}

          <span dangerouslySetInnerHTML={{ __html: type.title }} />
        </Link>
      ))}
    </SubTypesContainer>
  )
}

const SubMore = ({ icon, data }) => {
  return (
    <SubTypesContainer>
      {data.map((item, i) => (
        <Link key={i} to={`${item.url}`}>
          {icon && (
            <HexagonIcon
              iconSvg={<TypeIconSvg name={item.name} />}
              className="-icon"
              aria-hidden="true"
              title={item.title}
              bgColor={item.color}
            />
          )}

          <span dangerouslySetInnerHTML={{ __html: item.title }} />
        </Link>
      ))}
    </SubTypesContainer>
  )
}

const PrimaryNav = () => {
  const {
    pages,
    config: {
      types,
      header: { primaryNav },
    },
  } = useStaticQuery(graphql`
    query {
      pages: allPagesJson {
        nodes {
          url: path
          title
        }
      }

      config: configJson {
        types {
          name
          slug
          color
          title
        }

        header {
          primaryNav: primary_nav {
            planYourTrip: plan_your_trip
            exploreNav: explore_nav {
              exploreNavTitle: explore_nav_title
              exploreNavItems: items {
                title
                url
              }
            }

            items {
              title
              url
            }
          }
        }
      }
    }
  `)

  const items = [
    {
      title: primaryNav.planYourTrip,
      sub: {
        type: `types`,
        data: types,
      },
    },
    {
      title: primaryNav.exploreNav.exploreNavTitle,
      sub: {
        type: `more`,
        data: [...primaryNav.exploreNav.exploreNavItems, ...pages.nodes],
      },
    },
  ]

  primaryNav.items.forEach(({ title, url }) => items.push({ title, url }))

  return (
    <Container>
      <Base>
        {items.map((base, i) => (
          <li key={i} data-has-sub={!!base.sub}>
            <ButtonLink to={base.url}>
              <span dangerouslySetInnerHTML={{ __html: base.title }} />

              {base.sub && <ChevronDownSvg />}
            </ButtonLink>

            {base.sub && (
              <Sub>
                {base.sub.type === `types` && (
                  <SubTypes icon={true} data={base.sub.data} />
                )}
                {base.sub.type === `more` && (
                  <SubMore icon={false} data={base.sub.data} />
                )}
              </Sub>
            )}
          </li>
        ))}
      </Base>
    </Container>
  )
}

// PrimaryNav.propTypes = {
//   data: PropTypes.array,
// }

export default PrimaryNav
