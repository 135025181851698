import { rem } from "polished"

const cookiesConsent = {
  textColor: ({ theme }) => theme.colors.white,
  borderColor: ({ theme }) => theme.colors.black,
  backgroundColor: ({ theme }) => theme.colors.green,
  br: rem(5),
  buttonFont: ({ theme }) => theme.fonts.set(`primary`, `bold`),
  buttonTextColor: ({ theme }) => theme.colors.green,
  buttonBackgroundColor: ({ theme }) => theme.colors.white,
  buttonBr: rem(8),
}

export default cookiesConsent
