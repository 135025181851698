import PropTypes from "prop-types"

import { ReactComponent as SvgHouse } from "../../assets/images/icons/house.svg"
import { ReactComponent as SvgKnifeFork } from "../../assets/images/icons/knife-fork.svg"
import { ReactComponent as SvgHiking } from "../../assets/images/icons/hiking.svg"
import { ReactComponent as SvgMore } from "../../assets/images/icons/more.svg"

const icons = {
  stays: SvgHouse,
  eatDrink: SvgKnifeFork,
  hiking: SvgHiking,
  more: SvgMore,
}

const TypeIconSvg = ({ name, ...props }) => {
  if (!name) return null

  return icons[name](props)
}

TypeIconSvg.propTypes = {
  name: PropTypes.string,
}

export default TypeIconSvg
