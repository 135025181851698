const typesCategories = {
  hiking: [
    `33`, // hiking
    `109`, // circular-trail
    `96`, // pilgrim-trail
    `7`, // walking
  ],
  more: [], // everything else (catch all)
}

const typesCategoriesIncluded = typesCategories
const typesCategoriesPreselected = typesCategories

module.exports = {
  typesCategories,
  typesCategoriesIncluded,
  typesCategoriesPreselected,
}
