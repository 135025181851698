import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import styled from "styled-components"
import { rem } from "polished"
import { Meta } from "@outdoormap/gatsby-plugin-brand-sites"

import Layout from "../components/layout"
import Map from "../components/map"
import { Label2 } from "../components/styled/label"
import HexagonIcon from "../components/hexagon-icon"
import { ReactComponent as SvgMapCompass } from "../assets/images/icons/map-compass.svg"

const Container = styled.article``

const Header = styled.header`
  padding: ${rem(40)} 0;
  color: ${({ theme }) => theme.colors.green};
  background-color: ${({ theme }) => theme.colors.white};

  @media ${({ theme }) => theme.mq.xxlargeDown} {
    padding-top: ${rem(20)};
    padding-bottom: ${rem(20)};
  }

  @media ${({ theme }) => theme.mq.mediumDown} {
    padding-top: ${rem(10)};
    padding-bottom: ${rem(10)};
  }

  h1 {
    ${({ theme }) => theme.grid.container()}

    display: flex;
    align-items: center;
  }

  .-icon {
    width: ${rem(45)};
    height: ${rem(45)};
    margin-right: ${rem(10)};

    @media ${({ theme }) => theme.mq.mediumDown} {
      width: ${rem(35)};
      height: ${rem(35)};
    }

    svg {
      path {
        fill: ${({ theme }) => theme.colors.white};
      }
    }
  }
`

const MapWrap = styled.div`
  width: 100%;
  height: 80vh;
`

const MapPage = ({ data: { config } }) => {
  return (
    <Layout>
      <Container>
        <Header>
          <h1>
            <HexagonIcon
              iconSvg={<SvgMapCompass />}
              className="-icon"
              aria-hidden="true"
            />

            <Label2
              dangerouslySetInnerHTML={{
                __html: config.pages.map.title,
              }}
            />
          </h1>
        </Header>

        <MapWrap>
          <Map
            query={`guide=${process.env.GATSBY_NATURKARTAN_GUIDE_ID}`}
            menu="fullscreen"
            scrollZoom="false"
            styleControl="true"
            zoomControl="true"
            measureControl="true"
            locationControl="true"
            printControl="true"
            legendControl="true"
            legend="true"
          />
        </MapWrap>
      </Container>
    </Layout>
  )
}

MapPage.propTypes = {
  data: PropTypes.object,
}

export default MapPage

export const Head = ({ data: { config } }) => {
  return <Meta data={{ title: config.pages.map.title }} />
}

export const pageQuery = graphql`
  query {
    config: configJson {
      pages {
        map {
          title
        }
      }
    }
  }
`
