export default {
  white: `#fff`,
  black: `#111`,
  green: `#465555`,
  offWhite: `#fff5eb`,
  salmon: `#ffb496`,
  naturkartanMapBg: `#f3f3ef`,
  // non-figma colors:
  greenRich: `#41885c`,
  red: `#884141`,
  purple: `#735967`,
}
