import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { rem } from "polished"
import { useCookies } from "react-cookie"
import { Link } from "@outdoormap/gatsby-plugin-brand-sites"

import GoodToKnow from "./good-to-know"
import About from "./about"
import Menus from "./menus"
import { ParagraphSmall } from "../styled/paragraph"
import { Anchor } from "../styled/anchor"
import naturkartanLogoUrl from "../../assets/images/naturkartan-logo.svg"

const COOKIES_CONSENT_NAME = `cookies-consent`

const Container = styled.footer``

const Main = styled.div`
  padding-top: ${rem(70)};
  padding-bottom: ${rem(70)};
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.green};

  @media ${({ theme }) => theme.mq.xxlargeDown} {
    padding-top: ${rem(40)};
    padding-bottom: ${rem(40)};
    font-size: ${rem(14)};
  }

  > div {
    ${({ theme }) => theme.grid.container()}
  }
`

const Sections = styled.div`
  ${({ theme }) => theme.grid.grid()}
  ${({ theme }) => theme.grid.gridGutterX(null, { nested: true })}
  ${({ theme }) => theme.grid.gridGutterY(60)}

  @media ${({ theme }) => theme.mq.smallDown} {
    ${({ theme }) => theme.grid.gridGutterY(40)}
  }

  > div {
    ${({ theme }) => theme.grid.cell(6)}

    @media ${({ theme }) => theme.mq.xlargeDown} {
      ${({ theme }) => theme.grid.cell(12)}

      display: flex;
      justify-content: center;
      text-align: center;
    }
  }
`

const AboutStyled = styled(About)`
  width: 82%;
  max-width: ${rem(560)};

  @media ${({ theme }) => theme.mq.xlargeDown} {
    width: 100%;
  }
`

const Copyright = styled(ParagraphSmall)`
  margin-top: 3em;
  color: inherit;
  opacity: 0.5;

  ${Anchor} {
    color: ${({ theme }) => theme.colors.white};
  }
`

const Foot = styled.div`
  margin-top: 4em;
  padding-top: 3em; /* 40 */
  border-top: 1px solid ${({ theme }) => theme.colors.green};
  text-align: center;

  a {
    transition: opacity 0.2s ${({ theme }) => theme.easings.default};

    &:hover {
      opacity: 0.7;
    }

    &:active {
      transition: none;
      opacity: 0.4;
    }
  }

  img {
    width: 10em;
    height: 2.5em;
    display: inline-block;
  }
`

const Footer = () => {
  const [cookies, , removeCookie] = useCookies([COOKIES_CONSENT_NAME])

  const cookiesConsented = !!cookies[COOKIES_CONSENT_NAME]

  const resetCookiesClick = () => {
    removeCookie(COOKIES_CONSENT_NAME)
    window.scrollTo({ top: 0, behavior: `instant` })
    window.location.reload()
  }

  const { translations, privacyPolicyPage } = useStaticQuery(graphql`
    query {
      translations: translationsJson {
        global {
          resetCookies
        }
      }

      privacyPolicyPage: pagesJson(nk_id: { eq: "privacypolicy" }) {
        title
        path
      }
    }
  `)

  return (
    <Container>
      <GoodToKnow />

      <Main>
        <div>
          <Sections>
            <div>
              <AboutStyled />
            </div>

            <div>
              <div>
                <Menus />

                <Copyright>
                  © Bruksleden & OutdoorMap
                  {` `}
                  {privacyPolicyPage && (
                    <>
                      <Anchor as={Link} to={privacyPolicyPage.path}>
                        {privacyPolicyPage.title}
                      </Anchor>
                      .
                    </>
                  )}
                  {` `}
                  {cookiesConsented && (
                    <>
                      <Anchor
                        as="button"
                        type="button"
                        onClick={resetCookiesClick}
                      >
                        {translations.global.resetCookies}
                      </Anchor>
                      .
                    </>
                  )}
                </Copyright>
              </div>
            </div>
          </Sections>

          <Foot>
            <Link
              to="https://naturkartan.se/sv?utm_source=bruksleden&utm_medium=website"
              target="_blank"
              title="Naturkartan"
              aria-label="Naturkartan"
            >
              <img src={naturkartanLogoUrl} alt="Naturkartan" />
            </Link>
          </Foot>
        </div>
      </Main>
    </Container>
  )
}

export default Footer
