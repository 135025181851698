const globalNotice = {
  color: ({ theme }) => theme.colors.white,
  backgroundColor: {
    info: ({ theme }) => theme.colors.greenRich,
    warning: ({ theme }) => theme.colors.purple,
    critical: ({ theme }) => theme.colors.red,
  },
}

export default globalNotice
