import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import { rem } from "polished"
import { Link } from "@outdoormap/gatsby-plugin-brand-sites"

import SitesCarousel from "../sites-carousel"
import TypeIconSvg from "../type-icon-svg"
import { Label3 } from "../styled/label"
import { Heading2 } from "../styled/heading"
import { Paragraph } from "../styled/paragraph"
import { anchorStyles } from "../styled/anchor"
import { ReactComponent as SvgArrowRight } from "../../assets/images/icons/arrow-right.svg"

const Header = styled.header`
  margin-bottom: ${rem(30)};
  padding-right: ${rem(50)};
  // padding-bottom: ${rem(10)};
  position: relative;
  // border-bottom: 1px solid ${({ theme }) => theme.colors.salmon};

  i,
  h2 {
    margin-bottom: ${rem(8)};
  }

  i,
  h2 {
    color: ${({ theme }) => theme.colors.green};
  }

  p {
    color: ${({ theme }) => theme.colors.green};
  }

  svg {
    width: ${rem(32)};
    height: ${rem(32)};
    position: absolute;
    top: 0;
    right: 0;
  }
`

const Cta = styled.footer`
  margin-top: ${rem(30)};

  a {
    ${anchorStyles}

    &:hover {
      svg {
        transform: translateX(0.3em);
      }
    }

    span {
      margin-right: 0.4em;
    }

    svg {
      width: 1.4em;
      height: 1.4em;
      vertical-align: middle;
      transition: transform 0.2s ${({ theme }) => theme.easings.default};
    }
  }
`

const Container = styled.section`
  ${({ theme }) => theme.grid.container()}

  width: 100%;
  margin-top: ${rem(40)};
  margin-bottom: ${rem(40)};

  ${({ $color }) =>
    $color &&
    css`
      ${Header} svg,
      ${Cta} a {
        color: ${({ theme }) => theme.colors[$color]};
      }
    `}
`

const IndexSites = ({ type, sites }) => {
  const { translations } = useStaticQuery(graphql`
    query {
      translations: translationsJson {
        global {
          seeAll
        }
      }
    }
  `)

  if (!sites || !sites.length) return null

  return (
    <Container $color={type.color}>
      <Header>
        <Label3 dangerouslySetInnerHTML={{ __html: type.title }} />

        {type.tagline && (
          <Heading2 dangerouslySetInnerHTML={{ __html: type.tagline }} />
        )}

        {type.description && (
          <Paragraph dangerouslySetInnerHTML={{ __html: type.description }} />
        )}

        <TypeIconSvg name={type.name} aria-hidden="true" />
      </Header>

      <SitesCarousel
        data={sites}
        type={type.name}
        visibleSlides={type.name === `hiking` ? 3 : 4}
        hideTypeIcons={true}
      />

      <Cta>
        <Link to={`/${type.slug}`}>
          <span
            dangerouslySetInnerHTML={{ __html: translations.global.seeAll }}
          />

          <SvgArrowRight aria-hidden="true" />
        </Link>
      </Cta>
    </Container>
  )
}

IndexSites.propTypes = {
  type: PropTypes.object.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  ctaTitle: PropTypes.string,
  sites: PropTypes.array,
}

export default IndexSites
