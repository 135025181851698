import styled, { css } from "styled-components"

// const label1Styles = css``

const label2Styles = css`
  ${({ theme }) => theme.fonts.set(`secondary`, `black`)}

  display: block;
  text-transform: uppercase;
  font-size: 1.5em; /* 24 */

  @media ${({ theme }) => theme.mq.mediumDown} {
    font-size: 1.25em; /* 20 */
  }
`

const label3Styles = css`
  ${({ theme }) => theme.fonts.set(`secondary`, `black`)}

  display: block;
  text-transform: uppercase;
`

const label4Styles = css`
  ${({ theme }) => theme.fonts.set(`secondary`, `black`)}

  display: block;
  text-transform: uppercase;
  font-size: 0.75em; /* 12 */
`

// const Label1 = styled.i`
//   ${label1Styles}
// `

const Label2 = styled.i`
  ${label2Styles}
`

const Label3 = styled.i`
  ${label3Styles}
`

const Label4 = styled.i`
  ${label4Styles}
`

export {
  // Label1,
  Label2,
  Label3,
  Label4,
  // label1Styles,
  label2Styles,
  label3Styles,
  label4Styles,
}
